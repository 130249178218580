@use 'variables' as v;
@use 'metrics.scss' as m;
@use 'chart.scss' as c;

.or-metric-widget {
  border-radius: 3px;
  color: v.$text-inverse;

  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;

  &.or-metric-presence {
    background-color: m.$metric-presence;
  }

  &.or-metric-occupancy {
    background-color: m.$metric-occupancy;
  }

  &.or-metric-dim-level {
    background-color: m.$metric-dim-level;
  }

  &.or-metric-light-level {
    background-color: m.$metric-light-level;
  }

  &.or-metric-energy-consumption {
    background-color: m.$metric-energy-consumption;
  }

  &.or-metric-energy-occupancy-ratio {
    background-color: m.$metric-energy-occupancy-ratio;
  }

  &.or-metric-connection-quality {
    background-color: m.$metric-connection-quality;
  }

  &.or-metric-temperature {
    background-color: m.$metric-temperature;
  }

  &.or-metric-pressure {
    background-color: m.$metric-pressure;
  }

  &.or-metric-humidity {
    background-color: m.$metric-humidity;
  }

  &.or-metric-fuji-ambient-light {
    background-color: m.$metric-fuji-ambient-light;
  }

  &.or-metric-sound {
    background-color: m.$metric-sound;
  }

  &.or-metric-co2 {
    background-color: m.$metric-co2;
  }

  &.or-busy {
    .or-metric-widget-content {
      opacity: 0.35;
    }
  }

  &.or-active {
    .or-metric-widget-content {
      height: auto;
      transition: all 0.75s;
      max-height: 100vh;
      opacity: 1;
    }
  }

  .or-button {
    background-color: transparent;
    box-shadow: none;
    color: inherit;
  }

  .or-metric-widget-heading {
    width: 100%;
    position: relative;

    .or-inline-item {
      &:last-child {
        position: absolute;
        right: 0;
      }
    }
  }
}

.or-metric-widget-content {
  height: 0;
  max-height: 0;
  overflow: hidden;
  opacity: 0;
}

.or-metric-widget-toggle {
  border-radius: 0;
  color: inherit;
  padding: 0 10px;
  position: relative;
  text-align: left;
  width: 100%;

  &.or-active {
    opacity: 1;
  }
}

.or-metric-widget-label {
  font-weight: 600;
  letter-spacing: -0.02em;
}

.or-metric-widget-navigation {
  height: v.$icon-size-small;
  margin: 4px 0;
  position: relative;

  .or-inline-item {
    margin: 0;
  }

  .or-button {
    line-height: v.$icon-size-small;
    min-width: v.$icon-size-small;
  }
}

.or-metric-widget-navigation-shift {
  left: 10px;
  position: absolute;
  top: 0;
  z-index: 2;
}

.or-metric-widget-navigation-zoom {
  position: absolute;
  right: 10px;
  top: 0;
  z-index: 2;
}

.or-metric-widget-navigation-reset {
  display: block;
  left: 50%;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  z-index: 1;
}

.or-small.or-small-to-tiny {
  background-size: 16px;
  width: 16px;
  height: 16px;
}
