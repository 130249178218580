@use 'variables';

.or-site-menu-link {
  background-color: transparent;
  color: variables.$text-inverse;
  opacity: 1;
  padding: 0;

  .or-button-label {
    display: none;
  }

  &.or-active {
    background-color: variables.$bg-accent;
    color: variables.$text-inverse;
    padding: 0 16px;

    .or-button-label {
      display: inline-block;
    }
  }
}
