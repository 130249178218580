.or-form-bacnet-area-tile {
  padding: 0;
  display: block;

  &.or-form-bacnet-area-tile-enable-labels {
    .or-label {
      display: block;
    }

    .or-label-portal-id {
      display: block;
    }
  }

  .or-label {
    display: none;
    font-size: 0.9rem;
    margin-left: 5px;
    padding: 0;
    line-height: 17px;
    margin-bottom: 10px;
  }

  .or-label-portal-id {
    display: none;
    font-size: 0.9rem;
    margin-left: 0px;
    padding: 0;
    line-height: 17px;
    margin-bottom: 10px;
    opacity: 0.8;
  }

  .or-form-item {
    vertical-align: bottom;
    width: auto;

    &.or-form-bacnet-area-tile-id {
      width: 5%;
      text-align: center;
    }

    &.or-form-bacnet-area-tile-portal-id {
      width: auto;
    }

    &.or-form-bacnet-area-tile-name {
      width: 10%;
    }

    &.or-form-bacnet-area-tile-description {
      width: 20%;
    }

    &.or-form-bacnet-area-tile-gateway-info {
      width: 35%;
    }
  }
}
