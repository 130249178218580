@use '@angular/material' as mat;

$color-1: mat.m2-define-palette(mat.$m2-blue-palette, 400);
$color-2: mat.m2-define-palette(mat.$m2-green-palette, 400);
$color-3: mat.m2-define-palette(mat.$m2-red-palette, 300);
$color-4: mat.m2-define-palette(mat.$m2-indigo-palette, 300);
$color-5: mat.m2-define-palette(mat.$m2-teal-palette, 400);
$color-6: mat.m2-define-palette(mat.$m2-blue-grey-palette, 400);

$colors: (
        "blue": $color-1,
        "green": $color-2,
        "red": $color-3,
        "indigo": $color-4,
        "teal": $color-5,
        "grey": $color-6
);

@each $key, $palette in $colors {
  .mat-mdc-checkbox.checkbox-color-#{$key}.mat-mdc-checkbox-checked .mdc-checkbox__background,
  .mat-mdc-checkbox.checkbox-color-#{$key}.mat-mdc-checkbox-checked .mdc-checkbox__ripple {
    background-color: mat.m2-get-color-from-palette($palette) !important;
    border-color: mat.m2-get-color-from-palette($palette) !important;
  }

  .mat-mdc-checkbox.checkbox-color-#{$key}.mat-mdc-checkbox-checked .mdc-checkbox__checkmark-path {
    stroke: white !important; // You can also change this based on your requirements
  }

  .chip-color-#{$key}{
    background-color: mat.m2-get-color-from-palette($palette) !important;
    color: mat.m2-get-contrast-color-from-palette($palette, 600) !important;
  }
}