@use 'variables' as v;

.or-metric-selector-list {
  display: block;
}

.or-metric-selector-list-item {
  display: block;
  margin-bottom: 2px;

  &:last-child {
    margin-bottom: 0;
  }
}
