.or-buildings-list {
  display: block;
  min-width: 860px;
}

.or-buildings-list-item {
  display: inline-block;
  min-width: 215px;
  height: 280px;
  padding: 5px;
  vertical-align: top;
  width: 25%;
}

@media (min-width: 1400px) {
  .or-buildings-list-item {
    width: 20%;
  }
}

@media (min-width: 1800px) {
  .or-buildings-list-item {
    width: 12.5%;
  }
}

