@use 'variables' as v;

.or-schedule-rule-tile {
  color: v.$text-muted;

  .or-inline-tags, .or-inline-floors {
    line-height: 32px;
    vertical-align: middle;
  }
}

.or-schedule-rule-tile-setting {
  display: inline-block;
  line-height: v.$icon-size-medium;
  vertical-align: middle;
}

.or-schedule-rule-setting-tile-name,
.or-schedule-rule-setting-tile-value {
  color: v.$text;
  font-weight: 600;
}
