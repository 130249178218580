@use 'variables';

.or-simple-tag-list {
  display: block;

  li {
    background-color: variables.$bg-inverse-muted;
    border-radius: 3px;
    color: variables.$text-inverse;
    display: block;
    height: 32px;
    margin: 2px 0;
    padding-left: 8px;
    padding-right: 32px;
    position: relative;

    .or-simple-tag-list-name {
      display: inline-block;
      line-height: 32px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .or-button {
      background-color: transparent;
      box-shadow: none;
      min-width: auto;
      position: absolute;
      right: 0;
      top: 0;
      width: 32px;
    }
  }
}
