@use 'variables';


.or-lighting-configuration {

  width: 300px;

  .or-form-item {
    width: 30%;
    display: block;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: variables.$bg-negative;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: variables.$bg-positive;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 24px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  .or-lighting-configuration-list {
    display: inline-block;
    vertical-align: top;
    float: left;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    border-radius: 2px 2px;
    height: 600px;

    .or-lighting-configuration-item {
      margin-bottom: 10px;
      padding-left: 10px;
      padding-top: 10px;
      padding-right: 10px;
      border-radius: 5px 5px;
      width: 100%;
      height: 25%;
      box-shadow: variables.$shadow-light;
    }
  }

  .or-power-dbm {
    color: black;
    opacity: 1;
    width: 55px;
    position: relative;
    font-size: 0.9em;
    alignment: center;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 0px;
    padding-bottom: 0px;
    background-color: white;
    border-style: none;
  }

  .or-icon-shadow {
    -webkit-filter: drop-shadow(0px 0px 2.5px variables.$bg-dark-grey);
  }

  .or-lighting-configuration-list-item {
    border-radius: 3px;
    margin-bottom: 3px;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .or-lighting-configuration-item-margin {
    margin-top: 10px;
  }

  .or-form-footer {
    .or-inline-item {
      .or-button {
        width: 115px;
      }
    }
  }
  .no-padding{
    padding: 0px !important;
  }
}

.factory-default{
  color: #bbbbbb;
  font-style: italic;
  vertical-align: -webkit-baseline-middle;
  margin-left: 5%;
  margin-top: 20%;
}

datalist {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
}

datalist option {
  font-size: 0.9em;
}
