@use 'metrics.scss' as m;
@use 'variables';

$bg-emergency-gear: #ff0;
$bg-emergency-gear-muted: #660;
$bg-alert: #f00;
$bg-success: #0f0;
$bg-changed: #6cccf7;
$bg-changed-selected: #01567b;
.luminaire-driver {
  background-color: variables.$bg;
  border-radius: 24px;
  cursor: pointer;
  height: 24px;
  position: absolute;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transition: box-shadow variables.$transition-fast, background-color variables.$transition-medium;
  width: 24px;

  &:hover {
    box-shadow: variables.$shadow-dark;
    z-index: 100;

    .luminaire-driver-icon {
      opacity: 1;
    }

    .luminaire-driver-properties {
      opacity: 1;
      transform: translate(-50%, -8px) scale(1);
      -webkit-transform: translate(-50%, -8px) scale(1);
    }
  }

  &.is-changed.selected {
    box-shadow: 0 0 0 4px $bg-changed-selected;
  }

  &.selected {
    box-shadow: 0 0 0 4px variables.$bg-accent;

    .luminaire-driver-icon {
      opacity: 1;
      background-position: bottom;
    }
  }

  &.is-changed {
    box-shadow: 0 0 0 4px $bg-changed;
  }

  .luminaire-driver-scene-icon {
    width: 14px;
    margin: -3px 4px -1px 3px;
    padding-left: 3px;
  }

  .luminaire-driver-tags {
    height: 100%;
    left: 50%;
    overflow: visible;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    width: 100%;
    z-index: 2;
  }

  .luminaire-driver-tag {
    stroke: variables.$bg;
    stroke-alignment: inner;
    stroke-width: 2;
  }

  .luminaire-driver-icon {
    background-size: 12px;
    height: 12px;
    left: 50%;
    opacity: 0.6;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    width: 12px;
    z-index: 1;
  }

  &.driver {
    border-radius: 12px;
    height: 12px;
    width: 12px;

    &.selected {
      box-shadow: 0 0 0 2px variables.$bg-accent;
    }

    .luminaire-driver-tag {
      stroke-width: 1;
    }

    .luminaire-driver-icon {
      background-size: 6px;
      height: 6px;
      width: 6px;
    }
  }

  &.mapped {
    background-color: variables.$bg;
  }

  .luminaire-driver-icon {
    opacity: 0.6;
    background-position: top;
  }

  &.faulty {
    background-color: variables.$bg-negative;

    .luminaire-driver-icon {
      background-position: center;
      opacity: 1;
    }
  }

  &.disconnected {
    background-color: variables.$bg-deep-grey;

    .luminaire-driver-icon {
      background-position: center;
      opacity: 1;
    }
  }

  &.focused:after {
    border-radius: 64px;
    box-shadow: inset 0 0 0 16px rgba(variables.$bg-inverse, 0.2);
    content: '';
    height: 64px;
    left: 50%;
    position: absolute;
    top: 50%;
    width: 64px;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
  }

  &.disconnected {
    background-color: variables.$bg-deep-grey;

    .luminaire-driver-icon {
      background-position: center;
      opacity: 1;
    }
  }

  &.unmapped {
    background-color: variables.$bg-inverse;

    .luminaire-driver-icon {
      background-position: bottom;
      opacity: 1;
    }

    .luminaire-driver-tag {
      stroke: variables.$bg-inverse;
    }
  }

  &.has-emergency-gear {
    &.marked {
      background-color: $bg-emergency-gear;

      &.unmapped {
        background-color: $bg-emergency-gear-muted;

        .luminaire-driver-icon {
          background-position: center;
        }
      }
    }

    &.test-passed {
      background-color: $bg-success;
    }

    &.test-failed {
      background-color: $bg-alert;
    }

    &.test-initiated {
      background-color: variables.$bg-warning;

      .test-in-progress-animate {
        animation: sensor-pulse 1.5s infinite;
        -webkit-animation: sensor-pulse 1.5s infinite;
      }
    }
  }

  &.muted {
    opacity: 0.4;
  }
}

.luminaire-driver {
  .luminaire-driver-gatewaymode {
    background-position: center;
    opacity: 1;
  }
}

.luminaire-driver-border {
  border-radius: 24px;
  width: 24px;
  height: 24px;
}

.luminaire-driver-regular-shadow {
  box-shadow: variables.$shadow-light;
}

.luminaire-driver-light-level {
  box-shadow: 0 0 4px 4px m.$metric-dim-level;
}

.is-mapping {
  .luminaire-driver {
    &.hasMappingBeenAttempted {
      background-color: variables.$bg-accent;

      &.selected {
        box-shadow: inset 0 0 2px 2px variables.$bg, 0 0 0 4px variables.$bg-accent;
      }

      .luminaire-driver-icon {
        opacity: 1;
        background-position: center;
      }
    }

    &.mapped {
      background-color: variables.$bg;

      .luminaire-driver-icon {
        opacity: 0.6;
        background-position: top;
      }
    }

    &.duplicated-mapping {
      background-color: variables.$bg-negative;

      .luminaire-driver-icon {
        background-position: center;
        opacity: 1;
      }
    }

    &.notifying {
      animation: sensor-waves 1.5s infinite;
      -webkit-animation: sensor-waves 1.5s infinite;
      background-color: variables.$bg-accent;

      .luminaire-driver-icon {
        animation: sensor-pulse 1.5s infinite;
        -webkit-animation: sensor-pulse 1.5s infinite;
        background-position: bottom;
        opacity: 1;
      }
    }

    &.notification-sent {
      background-color: variables.$bg-accent !important;
      box-shadow: 0 0 0 4px variables.$bg !important;

      .luminaire-driver-icon {
        background-position: bottom !important;
        opacity: 1 !important;
      }
    }
  }
}

.luminaire-driver-gateway-properties {
  display: inline-block;
  margin-top: 5px;
}

.luminaire-driver-gateway-property {
  display: inline;
  padding: 0 2px;

  &.luminaire-driver-gateway-address {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;

    &.luminaire-driver-light-text {
      color: variables.$text-inverse;
    }

    &.luminaire-driver-dark-text {
      color: variables.$text-dark;
    }
  }

  &.luminaire-driver-device-address {
    background-color: rgba(variables.$bg-inverse, 0.6);
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    color: variables.$text-inverse;
  }
}

.luminaire-driver-properties {
  background-color: rgba(variables.$bg-inverse, 0.8);
  border-radius: 2px;
  bottom: 100%;
  box-shadow: variables.$shadow;
  color: variables.$text-inverse;
  font-size: 0.8rem;
  left: 50%;
  opacity: 0;
  position: absolute;
  transform: translate(-50%, -8px) scale(0);
  -webkit-transform: translate(-50%, -8px) scale(0);
  transform-origin: bottom;
  -webkit-transform-origin: bottom;
  transition: opacity variables.$transition-medium;
  white-space: nowrap;
  z-index: 3;

  &.ng-leave {
    opacity: 1;

    &.ng-leave-active {
      opacity: 0;
    }
  }

  &:before {
    border: solid 4px transparent;
    border-top-color: rgba(variables.$bg-inverse, 0.8);
    content: '';
    height: 1px;
    left: 50%;
    margin-left: -4px;
    position: absolute;
    top: 100%;
    width: 1px;
  }
}

.luminaire-driver-properties-header {
  box-shadow: 0 1px 0 0 variables.$outline-inverse;
  padding: 12px;

  &:last-child {
    box-shadow: none;
  }
}

.luminaire-driver-properties-lamp-type {
  background-color: variables.$bg-accent-muted;
  border-radius: 2px;
  color: variables.$text;
  display: block;
  font-size: 0.7rem;
  font-weight: 600;
  margin-top: 10px;
  padding: 0 6px;
  line-height: 1.4rem;
  text-align: center;
}

.luminaire-driver-properties-address {
  background-color: variables.$bg;
  border-radius: 100px;
  color: variables.$text;
  display: inline-block;
  font-size: 0.7rem;
  font-weight: 600;
  margin-left: 6px;
  padding: 0 6px;
  line-height: 1.4rem;

  &.unmapped {
    background-color: transparent;
    box-shadow: 0 0 0 1px variables.$text-inverse;
    color: variables.$text-inverse;
    opacity: 0.6;
  }
}

.luminaire-driver-properties-emergency-gear {
  background-color: $bg-emergency-gear;
  border-radius: 100px;
  color: variables.$text;
  display: inline-block;
  font-size: 0.7rem;
  font-weight: 600;
  margin-left: 6px;
  padding: 0 6px;
  line-height: 1.4rem;
}

.luminaire-driver-properties-driver {
  border-radius: 100px;
  display: inline-block;
  font-size: 0.7rem;
  font-weight: 600;
  margin-left: 6px;
  margin-right: 6px;
  margin-top: 6px;
  padding: 0 6px;
  line-height: 1.4rem;
}

.luminaire-driver-properties-mesh-addresses {
  background-color: variables.$bg-muted;
  border-radius: 100px;
  color: variables.$text;
  display: inline-block;
  font-size: 0.7rem;
  font-weight: 600;
  right: 0;
  margin-left: 6px;
  margin-top: 6px;
  padding: 0 6px;
  line-height: 1.4rem;

  &.unmapped {
    background-color: transparent;
    box-shadow: 0 0 0 1px variables.$text-inverse;
    color: variables.$text-inverse;
    opacity: 0.6;
  }
}

.luminaire-driver-properties-id {
  display: inline-block;
  vertical-align: middle;
  font-size: 1rem;
  margin: 0;
}

.luminaire-driver-properties-label {
  display: inline-block;
  vertical-align: middle;
  font-size: 1rem;
  margin: 0;
}

.luminaire-driver-properties-row {
  display: block;
}

.luminaire-driver-properties-rows {
  &.authorized {
    transition: all 0s;
  }
}

.factory-default {
  color: #bbbbbb;
  font-style: italic;
}

.luminaire-driver-properties-value {
  box-shadow: 0 1px 0 0 variables.$outline-inverse;
  padding: 6px;

  span {
    padding: 0 2px 0 2px;

    &:first-child {
      padding-left: 4px;
    }

    &:last-child {
      padding-right: 4px;
    }
  }

  &:last-child {
    box-shadow: none;
  }

  .lightlevel {
    &-left-col {
      width: 50%;
      display: inline-block;

      &-icon {
        margin-left: -1px;
      }

      &-text {
        display: inline-block;
        vertical-align: middle;
        margin-left: -1px;
      }
    }

    &-right-col {
      width: 50%;
      display: inline-block;
      vertical-align: middle;
      text-align: right;
      padding-right: 5px;

      &-bullet {
        width: 12px;
        height: 12px;
        display: inline-block;
        border-radius: 50%;
        margin-right: 2px;
        vertical-align: middle;

        &-on {
          background-color: m.$metric-dim-level;
        }

        &-off {
          border: 1px solid m.$metric-dim-level;
        }
      }

      &-text {
        display: inline-block;
        vertical-align: middle;
        margin-left: 4px;
      }
    }
  }
}

.luminaire-driver-properties-alerts {
  box-shadow: 0 1px 0 0 variables.$outline-inverse;
  padding: 6px;

  &:last-child {
    box-shadow: none;
  }
}

.luminaire-driver-properties-alert {
  background-color: variables.$bg-negative;
  border-radius: 2px;
  margin: 1px 0;
  padding: 6px;
}

.luminaire-driver-properties-alert-icon {
  display: inline-block;
  left: -4px;
  position: relative;
  vertical-align: middle;
}

.luminaire-driver-properties-alert-message {
  display: inline-block;
  vertical-align: middle;
}

.luminaire-driver-properties-tags {
  box-shadow: 0 1px 0 0 variables.$outline-inverse;
  padding: 6px;
}

.luminaire-driver-properties-tag {
  padding: 6px;
}

.luminaire-driver-properties-tag-color {
  border-radius: 16px;
  box-shadow: inset 0 0 0 2px variables.$bg-inverse;
  display: inline-block;
  height: 16px;
  left: -4px;
  position: relative;
  vertical-align: middle;
  width: 16px;
}

.luminaire-driver-properties-tag-name {
  display: inline-block;
  letter-spacing: -0.05em;
  vertical-align: middle;
}

.luminaire-driver-properties-test-result-icon {
  display: inline-block;
  left: -4px;
  position: relative;
  vertical-align: middle;
}

.luminaire-driver-properties-test-result-message {
  padding: 6px;
  display: inline-block;
  vertical-align: middle;
}

@-webkit-keyframes sensor-waves {
  0% {
    box-shadow: 0 0 0 20px rgba(variables.$bg, 1), 0 0 0 0 rgba(variables.$bg-accent, 0.3), 0 0 0 0 rgba(variables.$bg-accent, 0.6), 0 0 0 0 rgba(variables.$bg-accent, 0.9);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(variables.$bg, 0), 0 0 0 120px rgba(variables.$bg-accent, 0), 0 0 0 80px rgba(variables.$bg-accent, 0), 0 0 0 40px rgba(variables.$bg-accent, 0);
  }
}

@-moz-keyframes sensor-waves {
  0% {
    box-shadow: 0 0 0 20px rgba(variables.$bg, 1), 0 0 0 0 rgba(variables.$bg-accent, 0.3), 0 0 0 0 rgba(variables.$bg-accent, 0.6), 0 0 0 0 rgba(variables.$bg-accent, 0.9);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(variables.$bg, 0), 0 0 0 120px rgba(variables.$bg-accent, 0), 0 0 0 80px rgba(variables.$bg-accent, 0), 0 0 0 40px rgba(variables.$bg-accent, 0);
  }
}

@-ms-keyframes sensor-waves {
  0% {
    box-shadow: 0 0 0 20px rgba(variables.$bg, 1), 0 0 0 0 rgba(variables.$bg-accent, 0.3), 0 0 0 0 rgba(variables.$bg-accent, 0.6), 0 0 0 0 rgba(variables.$bg-accent, 0.9);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(variables.$bg, 0), 0 0 0 120px rgba(variables.$bg-accent, 0), 0 0 0 80px rgba(variables.$bg-accent, 0), 0 0 0 40px rgba(variables.$bg-accent, 0);
  }
}

@-o-keyframes sensor-waves {
  0% {
    box-shadow: 0 0 0 20px rgba(variables.$bg, 1), 0 0 0 0 rgba(variables.$bg-accent, 0.3), 0 0 0 0 rgba(variables.$bg-accent, 0.6), 0 0 0 0 rgba(variables.$bg-accent, 0.9);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(variables.$bg, 0), 0 0 0 120px rgba(variables.$bg-accent, 0), 0 0 0 80px rgba(variables.$bg-accent, 0), 0 0 0 40px rgba(variables.$bg-accent, 0);
  }
}

@keyframes sensor-waves {
  0% {
    box-shadow: 0 0 0 20px rgba(variables.$bg, 1), 0 0 0 0 rgba(variables.$bg-accent, 0.3), 0 0 0 0 rgba(variables.$bg-accent, 0.6), 0 0 0 0 rgba(variables.$bg-accent, 0.9);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(variables.$bg, 0), 0 0 0 120px rgba(variables.$bg-accent, 0), 0 0 0 80px rgba(variables.$bg-accent, 0), 0 0 0 40px rgba(variables.$bg-accent, 0);
  }
}

@-webkit-keyframes sensor-pulse {
  0% {
    transform: translate(-50%, -50%) scale(1.5);
    -webkit-transform: translate(-50%, -50%) scale(1.5);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    -webkit-transform: translate(-50%, -50%) scale(1);
  }
}

@-moz-keyframes sensor-pulse {
  0% {
    transform: translate(-50%, -50%) scale(1.5);
    -webkit-transform: translate(-50%, -50%) scale(1.5);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    -webkit-transform: translate(-50%, -50%) scale(1);
  }
}

@-ms-keyframes sensor-pulse {
  0% {
    transform: translate(-50%, -50%) scale(1.5);
    -webkit-transform: translate(-50%, -50%) scale(1.5);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    -webkit-transform: translate(-50%, -50%) scale(1);
  }
}

@-o-keyframes sensor-pulse {
  0% {
    transform: translate(-50%, -50%) scale(1.5);
    -webkit-transform: translate(-50%, -50%) scale(1.5);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    -webkit-transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes sensor-pulse {
  0% {
    transform: translate(-50%, -50%) scale(1.5);
    -webkit-transform: translate(-50%, -50%) scale(1.5);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    -webkit-transform: translate(-50%, -50%) scale(1);
  }
}

@-webkit-keyframes sensor-waves {
  0% {
    box-shadow: 0 0 0 20px rgba(variables.$bg, 1), 0 0 0 0 rgba(variables.$bg-accent, 0.3), 0 0 0 0 rgba(variables.$bg-accent, 0.6), 0 0 0 0 rgba(variables.$bg-accent, 0.9);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(variables.$bg, 0), 0 0 0 120px rgba(variables.$bg-accent, 0), 0 0 0 80px rgba(variables.$bg-accent, 0), 0 0 0 40px rgba(variables.$bg-accent, 0);
  }
}

@-moz-keyframes sensor-waves {
  0% {
    box-shadow: 0 0 0 20px rgba(variables.$bg, 1), 0 0 0 0 rgba(variables.$bg-accent, 0.3), 0 0 0 0 rgba(variables.$bg-accent, 0.6), 0 0 0 0 rgba(variables.$bg-accent, 0.9);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(variables.$bg, 0), 0 0 0 120px rgba(variables.$bg-accent, 0), 0 0 0 80px rgba(variables.$bg-accent, 0), 0 0 0 40px rgba(variables.$bg-accent, 0);
  }
}

@-ms-keyframes sensor-waves {
  0% {
    box-shadow: 0 0 0 20px rgba(variables.$bg, 1), 0 0 0 0 rgba(variables.$bg-accent, 0.3), 0 0 0 0 rgba(variables.$bg-accent, 0.6), 0 0 0 0 rgba(variables.$bg-accent, 0.9);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(variables.$bg, 0), 0 0 0 120px rgba(variables.$bg-accent, 0), 0 0 0 80px rgba(variables.$bg-accent, 0), 0 0 0 40px rgba(variables.$bg-accent, 0);
  }
}

@-o-keyframes sensor-waves {
  0% {
    box-shadow: 0 0 0 20px rgba(variables.$bg, 1), 0 0 0 0 rgba(variables.$bg-accent, 0.3), 0 0 0 0 rgba(variables.$bg-accent, 0.6), 0 0 0 0 rgba(variables.$bg-accent, 0.9);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(variables.$bg, 0), 0 0 0 120px rgba(variables.$bg-accent, 0), 0 0 0 80px rgba(variables.$bg-accent, 0), 0 0 0 40px rgba(variables.$bg-accent, 0);
  }
}

@keyframes sensor-waves {
  0% {
    box-shadow: 0 0 0 20px rgba(variables.$bg, 1), 0 0 0 0 rgba(variables.$bg-accent, 0.3), 0 0 0 0 rgba(variables.$bg-accent, 0.6), 0 0 0 0 rgba(variables.$bg-accent, 0.9);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(variables.$bg, 0), 0 0 0 120px rgba(variables.$bg-accent, 0), 0 0 0 80px rgba(variables.$bg-accent, 0), 0 0 0 40px rgba(variables.$bg-accent, 0);
  }
}

@-webkit-keyframes sensor-pulse {
  0% {
    transform: translate(-50%, -50%) scale(1.5);
    -webkit-transform: translate(-50%, -50%) scale(1.5);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    -webkit-transform: translate(-50%, -50%) scale(1);
  }
}

@-moz-keyframes sensor-pulse {
  0% {
    transform: translate(-50%, -50%) scale(1.5);
    -webkit-transform: translate(-50%, -50%) scale(1.5);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    -webkit-transform: translate(-50%, -50%) scale(1);
  }
}

@-ms-keyframes sensor-pulse {
  0% {
    transform: translate(-50%, -50%) scale(1.5);
    -webkit-transform: translate(-50%, -50%) scale(1.5);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    -webkit-transform: translate(-50%, -50%) scale(1);
  }
}

@-o-keyframes sensor-pulse {
  0% {
    transform: translate(-50%, -50%) scale(1.5);
    -webkit-transform: translate(-50%, -50%) scale(1.5);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    -webkit-transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes sensor-pulse {
  0% {
    transform: translate(-50%, -50%) scale(1.5);
    -webkit-transform: translate(-50%, -50%) scale(1.5);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    -webkit-transform: translate(-50%, -50%) scale(1);
  }
}
