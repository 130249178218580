@use 'variables';

.or-light-group-list {
  padding: 0;
  position: relative;
  width: 100%;

  .or-tint {
    min-height: 100px;
    position: absolute;
    margin: -5px;
    border-radius: 5px;
  }
}

.or-light-group-list-item {
  background-color: rgba(variables.$bg-inverse, 0.8);
  border-radius: 3px;
  color: variables.$text-inverse;
  margin: 2px 0;
  padding-left: 32px;
  padding-right: 48px;
  position: relative;

  &:hover {
    background-color: rgba(variables.$bg-inverse, 1);
  }

  .or-light-group-name {
    cursor: pointer;
    display: inline-block;
    line-height: 32px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }

  .or-light-group-list-item-actions {
    position: absolute;
    right: 4px;
    top: 0;

    .or-inline-item {
      margin: 0;
    }

    .or-button {
      background-color: transparent;
      box-shadow: none;
      min-width: auto;
      width: 24px;
    }
  }

  .or-light-group-list-item-highlight {
    background-color: transparent;
    box-shadow: none;
    left: 0;
    opacity: 0.2;
    position: absolute;
    top: 0;
    width: auto;
  }

  &:hover {
    .or-light-group-list-item-highlight {
      opacity: 0.6;
    }
  }

  &.is-highlighted {
    background-color: variables.$bg-accent;

    .or-light-group-list-item-highlight {
      opacity: 1;
    }
  }
}

.or-light-group-list-actions {
  margin-top: 20px;

  .or-button {
    text-align: left;
  }
}

#orLightGroupListForm {
  .or-form-item {
    > input,
    > select {
      width: 100%;
    }
  }

  .or-simple-tag-list,
  .or-simple-floor-list {
    margin-bottom: 1rem;
  }

  .or-light-group-select-tag,
  .or-light-group-select-floor {
    position: relative;
    display: block;
    width: 100%;

    select {
      width: 100%;
    }

    .or-label {
      height: 100%;
      left: 0;
      padding-left: 8px;
      padding-right: 32px;
      pointer-events: none;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 10;
    }
  }
}
