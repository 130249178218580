@use 'variables' as v;

.or-banner {
  background-color: v.$bg-muted;
  border: dashed 2px v.$outline;
  border-radius: 6px;
  padding: 20px;
  max-width: 480px;
}

.or-banner-header {
  padding-bottom: 20px;

  h1 {
    font-size: 1.4rem;
    letter-spacing: -0.05em;
  }
}

.or-banner-content {
  padding-bottom: 20px;

  &:last-child {
    padding-bottom: 0;
  }

  p {
    line-height: 1.3;
    margin: 0.5em 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
