@use 'variables';

.or-form-user-info {
  .or-form-items {
    display: inline-block;
    vertical-align: top;
  }
}

.or-form-user-info-heading {
  font-size: 1.4em;
}

.or-form-permissions-heading {
  font-size: 1.4em;
  padding: 20px 5px;
}

.or-form-user-info-summary {
  margin: 0 20px;
  width: 100%;
}

.or-form-user-info-thumbnail {
  width: 80px;
  height: 80px;
}

.or-form-user-info-id {
  width: 20%;
  min-width: 50px;
}

.or-form-user-info-name {
  width: 23%;
  min-width: 150px;
}

.or-form-user-info-email {
  width: 23%;
  min-width: 250px;
}

.or-form-user-info-permission-item {
  margin: 0 20px;
}

.or-form-user-info-authority {
  color: variables.$text-inverse;
  border-radius: 3px;
  padding: 5px;
  margin: 3px;
  background-color: variables.$bg-dark-grey;

  &.or-no-authority {
    background-color: variables.$bg-negative;
  }

  &.or-inline-item {
    display: inline-block;
    margin-right: 3px;
    vertical-align: top;

    &:last-child {
      margin-right: 0 !important;
    }
  }
}
