@use 'variables';

.or-session-menu {

  .or-dropdown-content {
    left: auto;
    right: -10px;
    width: 160px;
  }

  .or-button {
    text-align: center;

    &.or-button-logout {
      width: 80%;
    }
  }

  .or-session-menu-settings {
    opacity: 0.2;
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    transition: opacity variables.$transition-fast;
  }

  .or-session-menu-settings:hover {
    opacity: 1;
  }
}
