@use 'variables' as v;

$bg-page-solid: rgba(v.$bg-page, 1);
$bg-page-transparent: rgba(v.$bg-page, 0);

#orNodes {
  .or-panel-selector {
    margin: 5px 5px 0;
  }

  .or-content {
    height: 88vh;
  }

  .or-panel {
    margin: 5px;
  }

  .or-panel-nodes {
    &.or-active {
      //flex-basis: 480px;
      flex-grow: 1;
    }

    .or-panel-content {
      position: relative;
      height: 100%;

      &:before,
      &:after {
        background: v.$bg-page;
        content: '';
        height: 5px;
        left: 0;
        position: absolute;
        width: 100%;
        z-index: 2;
      }
    }
  }

  .or-panel-floorplan {
    overflow: hidden;

    &.or-active {
      // flex-basis: 800px;
      flex-grow: 1;
    }
  }

  .or-panel-tags {
    &.or-active {
      // flex-basis: 240px;
    }
  }

  .or-panel-tenants {
    &.or-active {
      // flex-basis: 240px;
    }
  }
}
