.or-form-token-details {
  padding: 0;
  display: block;

  &.or-form-token-details-enable-labels {
    .or-label {
      display: block;
    }
  }

  .or-label {
    display: none;
    font-size: 0.9rem;
    padding: 0;
    line-height: 17px;
    margin-bottom: 10px;
  }

  .or-form-item {
    width: auto;

    &.or-form-token-details-token {
      width: 25%;
    }

    &.or-form-token-details-description {
      width: 20%;
    }

    &.or-form-token-details-tenant {
      width: auto;
    }

    &.or-form-token-details-authority {
      text-align: center;
      width: 4%;
    }

    &.or-form-token-details-ip-addresses {
      width: 20%;
    }

    &.or-form-token-details-roles {
      min-width: 13%;
    }
  }
}
