@use '@angular/material' as mat;
@use '_components-theme' as component;
@use 'chip-checkbox-styles';
@use 'variables';
@use "form-element-styles";

@include mat.elevation-classes();
@include mat.app-background();

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --text-inverse: #{variables.$bg-inverse};
  --shadow-dark: #{variables.$shadow};
  --bg-page: #{variables.$bg-page};
  --primary-accent: #{variables.$bg-accent};
  --bg-header: #{variables.$bg-table-header};
  --bg-dark-grey: #{variables.$bg-dark-grey};
}

.unauthorizedForBuilding {
  display: none!important;
}

$primary: mat.m2-define-palette(mat.$m2-blue-palette);
$accent: mat.m2-define-palette(mat.$m2-yellow-palette, A700, A100, A400);

$theme: mat.m2-define-light-theme((
        color: (
                primary: $primary,
                accent: $accent,
        ),
        typography: mat.m2-define-typography-config(
                $font-family: 'Roboto, Helvetica Neue, sans-serif',
        )
));


@include mat.all-component-themes($theme);
@include component.theme($theme);

@include mat.chips-density(-5)

