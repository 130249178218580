@use 'variables' as v;

.or-beacons-node-tile-id {
  color: v.$text-muted;
  width: 40px;

  .or-node-tile-value {
    font-size: 1.2rem;
    line-height: 28px;
  }
}

.or-beacons-node-group-id {
  color: v.$text-muted;
  width: 50px;

  .or-node-tile-value {
    font-size: 1.2rem;
    line-height: 28px;
  }
}

.or-beacons-node-tile-address {
  width: 50px;
}

.or-beacons-node-tile-enabled {
  width: 50px;
}

.or-beacons-node-tile-power-level {
  width: 50px;
}

.or-beacons-node-tile-beacon-content {
  width: 100px;
}

.or-beacons-node-tile-beacon-interval {
  width: 50px;
}

.or-beacons-node-tile-update-status {
  width: 50px;
}

.or-beacons-node-tile-uuid {
  width: 150px;
}

.or-beacons-node-tile-major {
  width: 50px;
}

.or-beacons-node-tile-minor {
  width: 50px;
}

.or-green {
  color: #000;
}

.or-red {
  color: #777;
}

.or-orange {
  color: #c60;
}

.or-mouseover-interval, .or-mouseover-content, .or-mouseover-not-available {
  width: 85px;
  color: white;
  opacity: 0.9;
  text-align: center;
  background-color: #4397D8;
  text-align: center;
  border-radius: 3px;
  transition: 0.4s;
  z-index: 9100;
  position: relative;
}

.or-mouseover-content {
  width: 120px;
}

.or-mouseover-not-available {
  background-color: #8EB9DA;
}

.or-opacity {
  opacity: 0.6;
}

.loader {
  width: 24px;
  height: 24px;
  display: inline-block;
  z-index: 9000;
  -webkit-animation: beacon-spin 0.25s linear infinite;
  animation: beacon-spin 0.25s linear infinite;
}

@-webkit-keyframes beacon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

@-moz-keyframes beacon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

@-ms-keyframes beacon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

@-o-keyframes beacon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

@keyframes beacon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
