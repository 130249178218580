@use 'variables';

.or-schedules-timescale-container {
  padding-left: 72px;
  position: relative;
}

.or-schedules-timescale {
  display: block;
  margin: 2rem 0;
  min-height: 20px;
  position: relative;
  width: 100%;
}

.or-schedules-timescale-ticks {
  border-right: variables.$border-outline;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  white-space: nowrap;
}

.or-schedules-timescale-tick {
  border-left: variables.$border-outline;
  display: inline-block;
  height: 100%;
  position: relative;
  vertical-align: top;
  width: 4.166666666%;
}

.or-schedules-timescale-tick-label {
  color: variables.$text-muted;
  font-size: 0.8rem;
  left: 0;
  position: absolute;
  right: 0;
  text-align: left;
  margin: 10px 0 10px -2.5ch;

  &.or-schedules-timescale-label-above {
    bottom: 100%;
  }

  &.or-schedules-timescale-label-below {
    top: 100%;
  }
}

.or-schedules-list {
  display: block;
  margin: 0;
}

.or-schedules-list-item {
  display: block;
  margin: 20px 0;
  position: relative;
}
