@use 'portal' as *;
@use 'mixins' as m;
@use 'variables';

$header-height: 30px;
$timespan-height: 18px;
$bg-paused: variables.$bg-inverse;
.or-schedule-summary {
  box-shadow: variables.$shadow;
  position: relative;
  transition: opacity variables.$transition-medium;

  &:hover {
    .or-schedule-summary-actions {
      opacity: 1;
    }

    .or-schedule-summary-header {
      background-color: variables.$bg-inverse;
    }

    .or-schedule-summary-content {
      background-color: variables.$bg;
    }
  }

  &:not(.or-schedule-summary-collapsed) {
    opacity: 1 !important;
  }

  &.or-schedule-summary-paused {
    opacity: 0.8;

    .or-schedule-summary-timespan {
      opacity: 0.4;
    }

    .or-schedule-summary-timespan-hour {
      &.or-active {
        background-color: $bg-paused;
      }
    }

    .or-schedule-summary-timespan-hour-label {
      color: variables.$text-inverse;
    }
  }

  &.or-schedule-summary-always-active {
    &.or-schedule-summary-collapsed {
      .or-schedule-summary-timespan {
        display: none;
      }

      .or-schedule-summary-weekdays {
        display: none;
      }
    }
  }
}

.or-schedule-summary-header {
  background-color: rgba(variables.$bg-inverse, 0.8);
  border-radius: 3px 3px 0 0;
  color: variables.$text-inverse;
  cursor: pointer;
  height: $header-height;
  position: relative;
  transition: background-color variables.$transition-medium;

  &:before,
  &:after {
    clear: both;
    content: '';
    display: block;
  }
}

.or-schedule-summary-header-description {
  @include m.clearfix;
}

.or-schedule-summary-header-running {
  background-color: variables.$bg-accent !important;
  color: variables.$text-inverse !important;

  .or-schedule-summary-weekday {
    background-color: transparent;
    box-shadow: inset 0 0 0 1px variables.$bg;

    &.or-filled {
      background-color: variables.$bg;
    }
  }
}

.or-schedule-summary-weekdays {
  height: $header-height;
  padding: 0 10px;
  position: relative;

  .or-inline-item {
    margin-right: 2px;
    position: relative;
    top: 50%;
    transform: translateY(-60%);
  }
}

.or-schedule-summary-weekday {
  background-color: transparent;
  border-radius: 8px;
  box-shadow: inset 0 0 0 1px variables.$bg;
  display: inline-block;
  height: 8px;
  vertical-align: middle;
  width: 8px;

  &.or-filled {
    background-color: variables.$bg;
  }
}

.or-schedule-summary-actions {
  background-color: transparent;
  bottom: 0;
  color: inherit;
  line-height: $header-height;
  opacity: 0.4;
  padding: 0;
  margin-right: 4px;
  position: absolute;
  right: 100%;
  top: 0;
  transition: opacity variables.$transition-medium;
  white-space: nowrap;
  z-index: 100;
}

.or-schedule-summary-action {
  height: $header-height;
  margin: 0;
  position: relative;
  width: variables.$icon-size-small;

  .or-button {
    box-shadow: none !important;
    background-color: transparent !important;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

.or-schedule-summary-control-actions {
  position: absolute;
  top: 3px;
  left: 0;
  transition: top 100ms;

  &.action-0 {
    top: 3px;
  }

  &.action-1 {
    top: -23px;
  }

  &.action-2 {
    top: -49px;
  }
}

.or-schedule-summary-control-action {
  height: variables.$icon-size-small;
  margin-bottom: 2px;
  padding: 0;
  position: relative;
  width: variables.$icon-size-small;
  transition: opacity 1s;

  &:not(.active) {
    opacity: 0;
  }

  &:hover {
    .or-schedule-summary-control-action-hint {
      opacity: 0.8;
      transform: translateY(-50%) scale(1);
      -webkit-transform: translateY(-50%) scale(1);
    }
  }
}

.or-schedule-summary-control-action-hint {
  background-color: variables.$bg-inverse;
  border-radius: 3px;
  color: variables.$text-inverse;
  font-size: 0.9rem;
  left: 100%;
  line-height: 2rem;
  margin-left: 4px;
  opacity: 0;
  padding: 0 8px;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) scale(0);
  -webkit-transform: translateY(-50%) scale(0);
  transform-origin: left;
  -webkit-transform-origin: left;
  transition: opacity variables.$transition-fast, transform variables.$transition-fast;
  white-space: nowrap;

  &:before {
    border: solid 5px transparent;
    border-right-color: variables.$bg-inverse;
    content: '';
    left: 0;
    position: absolute;
    top: 50%;
    transform: translate(-100%, -50%);
  }
}

.or-schedule-summary-title {
  line-height: $header-height;
  padding: 0 10px;
}

.or-schedule-summary-name {
  cursor: pointer;
  font-weight: 600;
  display: inline-block;
  line-height: 1.4rem;
  margin-right: 10px;
}

.or-schedule-summary-label {
  background-color: rgba(variables.$bg, 0.4);
  border-radius: 3px;
  color: variables.$text-inverse;
  display: inline-block;
  font-size: 0.8rem;
  line-height: 1.4rem;
  margin-left: 4px;
  padding: 0 6px;

  &.or-pulsate {
    animation: pulse-light variables.$transition-slow infinite alternate;

    &.ng-hide {
      opacity: 0;
    }
  }
}

.or-schedule-summary-timespan {
  background-color: rgba(variables.$bg-inverse, 0.1);
  height: $timespan-height;
  position: relative;
}

.or-schedule-summary-timespan-hours {
  position: relative;
  height: 100%;
}

.or-schedule-summary-timespan-hour {
  background-color: transparent;
  color: variables.$text-inverse;
  display: inline-block;
  height: 100%;
  overflow: visible;
  position: relative;
  vertical-align: top;
  width: 0.694444444%;

  &.or-active {
    background-color: rgba(variables.$bg-accent, 0.6);

    &:after {
      background-color: variables.$bg;
      content: '';
      height: 1px;
      left: 0;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.or-schedule-summary-timespan-hour-label {
  background-color: transparent;
  color: variables.$text-inverse;
  display: none;
  font-size: 0.8rem;
  line-height: 1rem;
  padding: 0 4px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  white-space: nowrap;
}

.or-schedule-summary-timespan-hour-label-start-time, .or-schedule-summary-timespan-hour-label-period {
  left: 0;
}

.or-schedule-summary-timespan-hour-label-end-time {
  right: 100%;
}

.or-schedule-summary-timespan-hour.or-active.or-start-equals-end:first-child,
.or-schedule-summary-timespan-hour.or-inactive + .or-schedule-summary-timespan-hour.or-active,
.or-schedule-summary-timespan-hour.or-active.or-first-active,
.or-schedule-summary-timespan-hour.or-last-inactive.or-start-close-to-end {
  z-index: 10;

  &:after {
    display: none;
  }

  .or-schedule-summary-timespan-hour-label-start-time, .or-schedule-summary-timespan-hour-label-period {
    display: block;
  }
}

.or-schedule-summary-timespan-hour.or-active.or-near-active-start,
.or-schedule-summary-timespan-hour.or-active.or-near-active-end,
.or-schedule-summary-timespan-hour.or-active.or-near-active-start.start-near-midnight,
.or-schedule-summary-timespan-hour.or-active.or-near-active-end.end-near-midnight {
  &:after {
    display: none;
  }

  &.or-start-close-to-end {
    &:after {
      display: block;
    }
  }
}

.or-schedule-summary-timespan-hour.or-active.or-first-active.or-start-close-to-end,
.or-schedule-summary-timespan-hour.or-active.or-near-active-start.start-near-midnight,
.or-schedule-summary-timespan-hour.or-active.or-near-active-end.end-near-midnight {
  &:after {
    display: block;
  }

  .or-schedule-summary-timespan-hour-label-start-time, .or-schedule-summary-timespan-hour-label-period {
    display: none;
  }
}

.or-schedule-summary-timespan-hour.or-inactive.or-last-inactive.or-start-close-to-end,
.or-schedule-summary-timespan-hour.or-inactive.or-last-inactive.start-near-midnight {
  .or-schedule-summary-timespan-hour-label-start-time, .or-schedule-summary-timespan-hour-label-period {
    display: block;
    left: inherit;
    right: 0;
    color: rgba(variables.$text, 0.6);
  }
}

.or-schedule-summary-paused {
  .or-schedule-summary-timespan-hour.or-inactive.or-last-inactive.or-start-close-to-end {
    .or-schedule-summary-timespan-hour-label-start-time, .or-schedule-summary-timespan-hour-label-period {
      color: variables.$text;
    }
  }
}

.or-schedule-summary-timespan-hour.or-active + .or-schedule-summary-timespan-hour.or-inactive {
  z-index: 10;

  &:after {
    display: none;
  }

  .or-schedule-summary-timespan-hour-label-end-time {
    display: block;
  }
}

.or-schedule-summary-timespan-hour.or-active.or-start-equals-end:last-child,
.or-schedule-summary-timespan-hour.or-active.or-last-active {
  &:after {
    display: none;
  }

  .or-schedule-summary-timespan-hour-label-end-time {
    display: block;
    right: 0;
  }
}

.or-schedule-summary-timespan-hour.or-active.or-last-active.or-start-close-to-end,
.or-schedule-summary-timespan-hour.or-active.or-last-active.end-near-midnight {
  .or-schedule-summary-timespan-hour-label-end-time {
    display: none;
  }
}

.or-schedule-summary-timespan-hour.or-first-inactive.or-start-close-to-end,
.or-schedule-summary-timespan-hour.or-first-inactive.end-near-midnight {
  .or-schedule-summary-timespan-hour-label-end-time {
    display: block;
    left: 0;
    color: rgba(variables.$text, 0.6);
  }
}

.or-schedule-summary-paused {
  .or-schedule-summary-timespan-hour.or-first-inactive.or-start-close-to-end {
    .or-schedule-summary-timespan-hour-label-end-time {
      color: variables.$text;
    }
  }
}

.or-schedule-summary-content {
  background-color: variables.$bg;
  border-radius: 0 0 3px 3px;
  color: variables.$text;
  padding: 10px;

  &.or-schedule-summary-content-overview {
    cursor: pointer;
    background-color: rgba(variables.$bg, 0.6);
  }
}

.or-schedule-summary-header.or-busy {
  animation: pulse-dark variables.$transition-slow infinite alternate;
}

.or-schedule-button {
  width: auto;
  margin: 3px;

  &.or-schedule-button-active {
    background-color: #EBA43A;
    font-weight: bold;
  }

  &.or-schedule-button-inactive {
    background-color: #E6CBB7;
    color: #707070;
  }
}

@-webkit-keyframes pulse-dark {
  0% {
    background-color: rgba(variables.$bg-inverse, 0.5);
  }
  100% {
    background-color: rgba(variables.$bg-inverse, 0.75);
  }
}

@-moz-keyframes pulse-dark {
  0% {
    background-color: rgba(variables.$bg-inverse, 0.5);
  }
  100% {
    background-color: rgba(variables.$bg-inverse, 0.75);
  }
}

@-ms-keyframes pulse-dark {
  0% {
    background-color: rgba(variables.$bg-inverse, 0.5);
  }
  100% {
    background-color: rgba(variables.$bg-inverse, 0.75);
  }
}

@-o-keyframes pulse-dark {
  0% {
    background-color: rgba(variables.$bg-inverse, 0.5);
  }
  100% {
    background-color: rgba(variables.$bg-inverse, 0.75);
  }
}

@keyframes pulse-dark {
  0% {
    background-color: rgba(variables.$bg-inverse, 0.5);
  }
  100% {
    background-color: rgba(variables.$bg-inverse, 0.75);
  }
}

@-webkit-keyframes pulse-light {
  0% {
    background-color: rgba(variables.$bg, 0.2);
  }
  100% {
    background-color: rgba(variables.$bg, 0.5);
  }
}

@-moz-keyframes pulse-light {
  0% {
    background-color: rgba(variables.$bg, 0.2);
  }
  100% {
    background-color: rgba(variables.$bg, 0.5);
  }
}

@-ms-keyframes pulse-light {
  0% {
    background-color: rgba(variables.$bg, 0.2);
  }
  100% {
    background-color: rgba(variables.$bg, 0.5);
  }
}

@-o-keyframes pulse-light {
  0% {
    background-color: rgba(variables.$bg, 0.2);
  }
  100% {
    background-color: rgba(variables.$bg, 0.5);
  }
}

@keyframes pulse-light {
  0% {
    background-color: rgba(variables.$bg, 0.2);
  }
  100% {
    background-color: rgba(variables.$bg, 0.5);
  }
}
