@use 'variables' as v;

.or-gateway-tags {
  .or-button {
    background-color: transparent;
    box-shadow: none;
    color: v.$text-inverse;
    padding: 0;
  }

  .or-icon-shadow {
    -webkit-filter: drop-shadow(0px 0px 2.5px v.$bg-dark-grey);
  }

  .or-gateway-tags-list-item {
    border-radius: 3px;
    margin-bottom: 3px;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }

    &.or-active {
      .or-gateway-tags-list-toggle {
        font-weight: 600;
        opacity: 1;
      }

      .or-gateway-tags-list-item-checkmark {
        visibility: visible;
      }
    }
  }

  .or-gateway-tags-list-item-checkmark {
    visibility: hidden;
  }

  .or-gateway-tags-list-toggle {
    letter-spacing: -0.02em;
    text-align: left;
    padding-left: 8px !important;
  }

  .or-gateway-tag-address {
    font-weight: bold;
    font-size: 0.8rem;
  }
}
