@use 'variables' as v;

#orLogin {
  background-color: v.$bg-inverse;
  bottom: 0;
  color: v.$text-inverse;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;

  .or-login-background {
    background-image: url('#{v.$path-images}/bg-login.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
    left: 0;
    opacity: 0.2;
    position: fixed;
    right: 0;
    top: 0;
  }

  .or-content {
    left: 50%;
    min-height: auto;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .or-portal-logo {
    margin-right: 20px;
    align: center;
  }

  .or-trigger-login {
    opacity: 1;
    width: auto;
  }

  .or-button {
    margin: 10px 0;
    display: block;
  }

  .or-logo-center {
    background-position-x: center;
  }

  .or-login {
    width: 250px;
  }

  .or-login-label {
    margin: 40px 0 20px;
    text-align: center;
    color: v.$text-inverse-light;
  }
}
