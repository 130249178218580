@use 'variables';

.or-simple-floor-list {
  display: block;

  li {
    background-color: rgba(variables.$bg-inverse, 0.8);
    border-radius: 32px;
    color: variables.$text-inverse;
    display: block;
    height: 32px;
    margin: 2px 0;
    padding-left: 32px;
    padding-right: 32px;
    position: relative;

    .or-simple-floor-list-number {
      background-color: variables.$bg;
      border-radius: 24px;
      color: variables.$text;
      font-size: 0.8em;
      font-weight: 600;
      height: 24px;
      left: 0;
      line-height: 24px;
      margin: 4px;
      min-width: 24px;
      position: absolute;
      text-align: center;
      top: 0;
    }

    .or-simple-floor-list-name {
      display: inline-block;
      line-height: 32px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .or-button {
      background-color: transparent;
      box-shadow: none;
      min-width: auto;
      position: absolute;
      right: 0;
      top: 0;
      width: 32px;
    }
  }
}
