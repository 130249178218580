@use 'variables';

.or-panel {
  &.or-solid {
    .or-panel-content {
      background-color: variables.$bg;
      border-radius: 3px;
      box-shadow: variables.$shadow;
      color: variables.$text;
      padding: 10px;
    }
  }

  &:not(.or-active) {
    .or-panel-content {
      display: none;
      overflow: hidden;
    }
  }
}

.or-panel-content {
  margin-top: 10px;
}
