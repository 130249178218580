@use 'variables';

.or-floorplan-zoom-control {
  padding: 10px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  z-index: 8;

  li {
    margin: 4px;
  }

  button {
    background-color: variables.$bg;
    border-radius: 100px;
    line-height: 1;
    padding: 4px;

    &:hover {
      background-color: variables.$bg-hover;
    }
  }
}
