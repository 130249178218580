@use 'variables';

.or-simple-weekday-list {

  li {
    border: variables.$border-outline;
    border-radius: 16px;
    display: inline-block;
    height: 32px;
    margin-left: 4px;
    min-width: 4em;
    padding: 0 8px;
    text-align: center;

    &:first-child {
      margin-left: 0;
    }

    &.is-active {
      background: variables.$bg-accent;
      color: variables.$text-inverse;
    }

    span {
      line-height: 32px;
    }
  }

  &.is-editable {

    li {
      cursor: pointer;
    }
  }
}
