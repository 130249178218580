@use 'variables';

.or-tint {
  background-color: variables.$bg;
  bottom: 0;
  color: variables.$text;
  left: 0;
  opacity: 0.6;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9000;
}

.or-tint-content {
  animation: or-tint-icon-spin 1s infinite;
  background-color: variables.$bg-inverse;
  border-radius: 1000rem;
  height: variables.$icon-size-small;
  left: 50%;
  opacity: 0.4;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: variables.$icon-size-small;
}

.or-tint-icon {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

@-webkit-keyframes or-tint-icon-spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
    -webkit-transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@-moz-keyframes or-tint-icon-spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
    -webkit-transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@-ms-keyframes or-tint-icon-spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
    -webkit-transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@-o-keyframes or-tint-icon-spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
    -webkit-transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@keyframes or-tint-icon-spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
    -webkit-transform: translate(-50%, -50%) rotate(-360deg);
  }
}
