.or-form-user-details {
  .or-form-item {
    width: auto;
    margin-right: 10px;
    vertical-align: middle;
  }

  .or-form-user-details-id {
    background-color: #EEE;
    font-size: 0.8em;
    color: grey;
    border-radius: 4px;
  }

  .or-form-user-details-image {
    width: 40px;
    height: 40px;
    background-size: contain;
    border-radius: 20px;

    &.or-form-user-details-dafault-image {
      background-size: cover;
      border: 2px solid black;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      margin: 4px 14px 4px 4px;
    }
  }

  .or-form-user-details-name {
    font-size: 1.4em;
    min-width: 150px;
  }

  .or-form-user-details-role {
    background-color: #777;
    color: white;
    border-radius: 4px;
    padding: 4px 8px;
    display: inline-block;
    margin-right: 4px;
    position: relative;
  }

  &.edit-mode {
    .or-form-user-details-role {
      padding: 4px 20px 4px 8px;
    }
  }

  .or-form-user-details-remove-role {
    margin-left: 5px;
    text-decoration: none;
    font-weight: bold;
    position: absolute;
  }
}
