@mixin transform($params) {
  transform: $params;
  -webkit-transform: $params;
}

@mixin clearfix {
  zoom: 1;
  &:after,
  &:before {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}
