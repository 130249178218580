@use 'variables' as v;

#orAnalytics {
  .or-floorplan-scale-percentage {
    text-align: center;
    margin-top: 1em;
  }

  .white {
    color: white;
    margin-bottom: 1em;
  }

  .floorplan-container {
    display: flex;
    flex-direction: row;
  }

  .or-floorplan {
    flex-grow: 9;
  }

  .or-floorplan-scale {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    backdrop-filter: blur(30px) saturate(1.3) contrast(1.3); // saturate and contrast values are same as the heatmap
    width: 100%;
    height: 100%;
  }

  .or-floorplan-scale-gradient {
    filter: opacity(70%) brightness(1.5);
  }

  .or-panel-selector {
    margin: 5px 5px 0;
  }

  .or-panel {
    margin: 5px;
  }

  .or-panel-metrics {
    &.or-active {
      flex-basis: 240px;
    }
  }

  .or-panel-floorplan {
    overflow: hidden;

    &.or-active {
      flex-basis: 800px;
      flex-grow: 1;
    }
  }

  .or-panel-tags {
    &.or-active {
      flex-basis: 240px;
    }
  }
}

.or-sub-header {
  padding: 10px 0;
}

.or-button-style {
  background-color: v.$bg-inverse;
  border-radius: 100px;
  line-height: 1;
  padding: 4px;
  opacity: 1;
}

.or-sub-panel-label {
  font-size: 1.2rem;
  letter-spacing: -0.05em;
}
