@use 'variables' as v;

$or-logo-image: v.$path-images + '/logo.small.png';

.or-footer {
  position: absolute;
  bottom: 0;
  color: v.$text-muted;
  font-size: 0.8rem;
  margin: 0 40px 15px;

  a {
    color: inherit;

    &:hover {
      color: v.$text;

      .or-icon {
        opacity: 1;
      }
    }

    .or-icon {
      opacity: 0.4;
      transition: opacity v.$transition-medium;
    }
  }

  .or-logo {
    background-image: url($or-logo-image);
    background-size: auto;
    height: 24px;
    opacity: 0.4;
    vertical-align: middle;
    width: 125px;

    &:hover {
      opacity: 1;
    }
  }

  .or-button {
    background-color: transparent;
    box-shadow: 0 0 0 2px v.$outline;

    &:hover {
      box-shadow: 0 0 0 2px v.$text;
    }
  }
}

.or-footer-section {
  display: inline-block;
  line-height: v.$icon-size-medium;
  padding-right: 20px;
  vertical-align: top;

  &:last-child {
    padding-right: 0;
  }
}

.or-footer-dialog {
  h1 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
  }

  h2 {
    font-size: 1.2rem;
    font-weight: bold;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
  }

  p {
    margin-block-start: 1em;
    margin-block-end: 1em;
  }

  ul {
    list-style-type: disc;
    padding-inline-start: 40px;
  }

  button {
    width: initial;
    box-shadow: initial;
  }

  input {
    display: none;
  }

  &.ngdialog {
    .ngdialog-content {
      width: 80%;
    }
  }

  .or-footer-dialog-button {
    text-align: center;
  }

  &.or-footer-dialog-privacy {
    button {
      display: none;
    }

    input {
      color: white;
      display: inline;
      width: 30%;

      &.accept {
        background: #8c4;
      }

      &.decline {
        background: #d22;
      }
    }
  }
}
