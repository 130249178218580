@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  // Get the color config from the theme.
  $color-config: mat.m2-get-color-config($theme);

  // Get the primary color palette from the color-config.
  $primary-palette: map.get($color-config, 'primary');

  .tellus-mat-card-header {
    // Read the 500 hue from the primary color palette.
    background-color: mat.m2-get-color-from-palette($primary-palette, 500);
    font-size: 20px;
  }
}

@mixin misc() {
  .tellus-mat-tab > div {
    flex-grow: 0;
  }
  mat-form-field label, input, mat-option>span, mat-select span, mat-card-subtitle, textarea {
    font-size: 13px !important;
    font-weight: normal !important;
  }
  mat-card-content {
    padding: 0 !important;
  }
  mat-form-field>div:first-child {
    background-color: unset !important;
  }
}

@mixin typography($theme) {
  // Get the typography config from the theme.
  $typography-config: mat.m2-get-typography-config($theme);

  .tellus-mat-card {
    font-family: mat.m2-font-family($typography-config);
  }
}

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
  @include misc();
}
