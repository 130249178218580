@use 'variables';

.or-inline-tags {
  display: inline-block;
  font-size: inherit;
  vertical-align: middle;

  &.or-editable {
    font-size: 1rem;

    .or-inline-tag {
      line-height: variables.$icon-size-medium;
      padding-right: variables.$icon-size-small;
    }

    .or-inline-tag-color {
      height: variables.$icon-size-tiny;
      width: variables.$icon-size-tiny;
    }

    .or-inline-tag-name {
      font-weight: 600;
    }
  }
}

.or-inline-tag {
  display: inline-block;
  position: relative;
  vertical-align: top;
}

.or-inline-tag-color {
  border-radius: 3px;
  display: inline-block;
  height: 8px;
  margin-right: 4px;
  vertical-align: middle;
  width: 8px;
}

.or-inline-tag-name {
  display: inline-block;
  font-weight: 400;
  vertical-align: middle;
}

.or-inline-tag-remove {
  border-radius: 0;
  bottom: 0;
  box-shadow: none !important;
  min-width: auto !important;
  opacity: 0.4;
  padding: 0 !important;
  position: absolute !important;
  right: 0;
  top: 0;
  transition: opacity variables.$transition-fast;
  width: variables.$icon-size-small !important;

  &:hover {
    opacity: 1;
  }

  .or-icon {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
