@use 'variables';

.or-lighting-test-schedule-tile {
  background-color: variables.$bg;
  border-radius: 3px;
  box-shadow: variables.$shadow;
  color: variables.$text;
}

.or-lighting-test-schedule-tile-header {
  background-color: variables.$bg-inverse-muted;
  color: variables.$text-inverse;
  min-height: 32px;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  h1 {
    line-height: 32px;
  }

  &.bg-disabled {
    background-color: variables.$bg-inverse-disabled;
  }
}

.or-lighting-test-schedule-tile-header-running {
  background-color: variables.$bg-accent !important;
  color: variables.$text-inverse !important;

  .or-schedule-summary-weekday {
    background-color: transparent;
    box-shadow: inset 0 0 0 1px variables.$bg;

    &.or-filled {
      background-color: variables.$bg;
    }
  }
}

.or-lighting-test-schedule-tile-actions {
  height: 100%;

  li {
    background-color: rgba(variables.$bg-inverse, 0.6);
    border-radius: 5px;
    padding: 4px 6px;

    button {
      background-color: transparent;
      box-shadow: none;
      color: variables.$text-inverse;
      outline: 0;
      padding: 0;

      min-height: 16px;
      height: 16px;
      display: flex;

      span.or-button-label {
        line-height: 16px !important;
      }
    }
  }
}

.or-lighting-test-schedule-tile-content {
  padding: 8px;

  .or-simple-floor-list,
  .or-simple-tag-list,
  .or-simple-weekday-list {
    display: inline-block;
    margin: 8px;
    vertical-align: top;

    > li {
      display: inline-block;
      margin-bottom: 0;
      margin-top: 0;
      vertical-align: top;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .or-simple-tag-list,
  .or-simple-floor-list {
    > li {
      padding-right: 8px;
      margin-left: 8px;
    }
  }

  .or-label {
    padding-right: 0;
  }
}

.or-lighting-test-schedule-tile-content-timerange-controls {
  margin: 0 8px;

  .or-simple-weekday-list {
    margin: 0;
  }
}

.or-lighting-test-schedule-tile-report {
  border-top: variables.$border-outline;
  margin: 0 8px;
  padding: 16px 0;

  &:last-child {
    padding-bottom: 8px;
  }
}

.or-lighting-test-schedule-tile-report-icon {
  background-color: variables.$text;
  border-radius: 16px;
  display: inline-block;
  height: 32px;
  margin-right: 24px;
  position: relative;
  vertical-align: middle;
  width: 32px;

  .or-lighting-test-schedule-tile-report-icon-type {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .or-lighting-test-schedule-tile-report-icon-status {
    background-color: variables.$text;
    border-radius: 100rem;
    box-shadow: 0 0 0 2px variables.$bg;
    left: 100%;
    position: absolute;
    top: 50%;
    transform: translate(-4px, -50%);
  }

  .or-lighting-test-schedule-tile-report-icon-status-ok {
    background-color: variables.$bg-positive;
  }

  .or-lighting-test-schedule-tile-report-icon-status-fail {
    background-color: variables.$bg-negative;
  }
}

.or-lighting-test-schedule-tile-report-summary {
  display: inline-block;
  vertical-align: middle;

  p {
    line-height: 1.2;
  }

  .or-lighting-test-schedule-tile-report-summary-last-run {
    font-weight: 600;
    letter-spacing: -0.025em;
  }

  .or-lighting-test-schedule-tile-report-summary-current-run {
    font-size: 0.9em;
    opacity: 0.6;
  }

  .or-lighting-test-schedule-tile-report-summary-next-run {
    font-size: 0.9em;
    opacity: 0.6;
  }
}

.or-lighting-test-schedule-tile-form-actions {
  border-top: variables.$border-outline;
  margin: 0 8px;
  padding: 16px 0 8px;
}

.or-schedule-button {
  width: auto;
  margin: 3px;

  &.or-schedule-button-active {
    background-color: #EBA43A;
    font-weight: bold;
  }

  &.or-schedule-button-inactive {
    background-color: #E6CBB7;
    color: #707070;
  }
}
