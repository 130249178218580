.or-tags-list-toggle {
  background-color: transparent;
  box-shadow: none;
}

.or-tags-list {
  .or-inline-item {
    width: 100%;
  }
  .or-button {
    border-radius: 2px;
    text-align: left;
  }
}